.Schedule{
    padding: 10px;
}

.HoursBreakDown {
    border: thin solid lightgrey;
    border-radius: 10px;
    display: inline-block;
    padding: 10px;
    height: 350px;
    width: 600px;
    overflow-y: scroll;
}

.RydeListContainer {
    display: inline-block;
    min-width: 400px;
    width: 60%;
}

.DriveInfo {
    font-size: 12pt;
}

.PickUp {
    font-size: 12pt;
    padding-bottom: 5px;
    padding-top: 5px;
}

.RydeTime {
    font-size: 14pt;
    font-weight: bold;
}

.ViewType {
    diplay: inline-block;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
}