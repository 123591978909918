.RydeListByHour {
    border: thin solid lightgrey;
    border-radius: 5px;
    height: 350px;
    min-width: 400px;
    overflow-y: auto;
    padding: 0px;
    width: 100%;
}

.RydeHourBlock {
    border-right: thin solid lightgrey;
    border-bottom: thin solid lightgrey;
    display: inline-block;
    height: 120px;
    min-width: 200px;
    padding: 0px;
    width: 25%;
}

.RydeDriveBlock {
    display: inline-block;
    min-width: 400px;
    padding: 0px;
    width: 100%;
}

.RydeDriveBlockOutline {
    display: inline-block;
    background-color: #ECFFDC;
    min-width: 400px;
    padding: 0px;
    width: 100%;
}
