.RydeList {
    border: thin solid lightgrey;
    border-radius: 5px;
    display: inline-block;
    height: 350px;
    min-width: 400px;
    overflow-y: auto;
    width: 100%;
}

.RydeListDriveBlock {
    background-color: #ECFFDC;
    border-bottom: thin solid lightgrey;
    display: inline-block;
    height: 120px;
    padding: 5px;
    min-width: 400px;
    width: 100%;
}

.RydeListDriveBlockPast {
    background-color: lightgrey;
    border-bottom: thin solid black;
    display: inline-block;
    height: 120px;
    padding: 5px;
    min-width: 400px;
    width: 100%;
}
