.Profile {
  margin: auto;
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 50px;
}

.ProfileButtons {
    padding-top: 50px;
}

.SignOut {
    text-align: right;
}

.Loading {
    margin: auto;
    padding-top: 30px;
    width: 300px;
}