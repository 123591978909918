.RideDetails {
    padding: 20px;
    display: flex;
}

.RideMap {
    display: flex;
    width: 50%;
}

.RideInfo {
    display: flex;
    width: 50%;
}

.RideInfoContainer {
    display: inline-block;
}

.DetailsTitle {
    font-size: 18pt;
    font-weight: bold;
}

.ServiceLevel, .Status {
    font-size: 12pt;
    padding: 5px;
}

.PrimaryPassenger, .DetailInfo {
    font-size: 14pt;
}

.Header {
    font-size: 16pt;
    font-weight: bold;
    padding-top: 20px;
}

@media (max-width: 800px) {
    .RideDetails {
        display: inline-block;
    }
    .RideMap {
        display: inline-block;
        width: 100%;
    }

    .RideInfo {
        display: inline-block;
        width: 100%s;
    }
}